import React, { memo } from '../../lib/teact/teact';
import { getActions } from '../../global';

import buildClassName from '../../util/buildClassName';

import useLang from '../../hooks/useLang';
import useLastCallback from '../../hooks/useLastCallback';

import Button from '../ui/Button';

import styles from './SwapSettingsButton.module.scss';

interface OwnProps {
  className?: string;
}

function SwapSettingsButton({ className }: OwnProps) {
  const { toggleSwapSettingsModal } = getActions();

  const lang = useLang();
  const handleClick = useLastCallback(() => {
    toggleSwapSettingsModal({ isOpen: true });
  });

  return (
    <Button
      ariaLabel={lang('Swap Settings')}
      onClick={handleClick}
      className={buildClassName(styles.button, className)}
    >
      <i className="icon icon-params" aria-hidden />
    </Button>

  );
}

export default memo(SwapSettingsButton);
