import {
  IS_AIR_APP,
  IS_ANDROID_DIRECT, IS_CAPACITOR, IS_EXTENSION, IS_FIREFOX_EXTENSION, IS_PACKAGED_ELECTRON,
} from '../config';
import { IS_ELECTRON } from './windowEnvironment';

export type BuildPlatform = 'web'
| 'chrome-extension'
| 'firefox-extension'
| 'electron'
| 'android'
| 'ios'
| 'ios-air'
| 'other';

export function getBuildPlatform(): BuildPlatform {
  if (IS_FIREFOX_EXTENSION) {
    return 'firefox-extension';
  } else if (IS_EXTENSION) {
    return 'chrome-extension';
  } else if (IS_CAPACITOR) {
    if (IS_ANDROID_DIRECT) {
      return 'android';
    } else if (IS_AIR_APP) {
      return 'ios-air';
    } else {
      return 'ios';
    }
  } else if (IS_ELECTRON || IS_PACKAGED_ELECTRON) {
    return 'electron';
  } else {
    return 'web';
  }
}

export function getFlagsValue() {
  return {
    IS_ANDROID_DIRECT,
    IS_CAPACITOR,
    IS_EXTENSION,
    IS_FIREFOX_EXTENSION,
    IS_PACKAGED_ELECTRON,
    IS_AIR_APP,
    IS_ELECTRON,
  };
}
