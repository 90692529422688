import btc from '../../../assets/coins/coin_btc.png';
import ton from '../../../assets/coins/coin_ton.png';
import trx from '../../../assets/coins/coin_trx.svg';
import usdt from '../../../assets/coins/coin_usdt.svg';

export const ASSET_LOGO_PATHS = {
  ton,
  btc,
  trx,
  usdt,
};
